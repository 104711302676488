import axios from "axios";
import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  Divider,
  Chip,
  useMediaQuery,
} from "@mui/material";
import {
  getR2aDetails
} from "../../config/apiUrl";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/actions/setUserInfo";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import $ from "jquery";
import jQuery from "jquery";
import R2aDropDownDetailsHeader from "./R2aDropDownDetailsHeader";
import R2aAuctionDetails from "./R2aAuctionDetails";
import R2AReportGraph from "./R2AReportGraph";
import R2aAuctionTableSection from "./R2aAuctionTableSection";
import { EmptyMsgIcon } from "../../components/SvgIcon/SvgIcon";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// DataGrid Master Details
function R2aReportComponent(props) {

  const dispatch = useDispatch();
  const [r2aId, setR2aId] = React.useState(null);
  const [Error, setError] = React.useState(false);
  const [r2aData, setR2aData] = React.useState({});
  const [mainR2aResponse, setMainR2aResponse] = React.useState({});
  const [selectedOption, setSelectedOption] = React.useState({});
  const [auctionDetail, setAuctionDetail] = React.useState({});
  const [expandedR2ADetails, setExpandedR2ADetails] = React.useState(true);
  const [expandedR2AChart, setExpandedR2AChart] = React.useState(true);
  // const [openR2ADialog, setOpenR2ADialog] = React.useState(false);
  const [openR2ADialog, setOpenR2ADialog] = React.useState(props.openR2ADialog || false);
  // console.log("props---", props)
  const expandedR2ADetailsState = (data) => {
    setExpandedR2ADetails(data)
  }

  // In mobile device Details section collpased
  const isR2ADetails = useMediaQuery("(min-width: 600px)");
  // Use useEffect to synchronize the state with the media query
  React.useEffect(() => {
    setExpandedR2ADetails(isR2ADetails); // Automatically set state based on media query
  }, [isR2ADetails]);

  const queryParams = new URLSearchParams(window?.location?.search);
  const [fromIframe, setFromIframe] = React.useState((queryParams.get('fromIframe') - 0) === 1 ? true : false);
  const [sortingData, setsortingData] = React.useState("descending");
  // const queryParams = new URLSearchParams(window?.location?.search);
  // let SessionID = queryParams.get('q');
  // const sessionID = queryParams.get('q');


  /*Api R2A Call*/
  React.useEffect(() => {
    const getRFQToAuctionData = async () => {
      try {
        let url = getR2aDetails(props.selctedRFQInquiryId, props.auctionR2AId);
        const response = await axios.get(url, configAxios);
        setR2aData(response.data.r2as);
        setMainR2aResponse(response.data);
        setSelectedOption({ name: response.data.r2as[0].number, id: response.data.r2as[0].id });
        const matchedObjects = response.data.r2as.find((item) => item.id == response.data.r2as[0].id);
        setAuctionDetail(matchedObjects);
      } catch (err) {
        setError('Error fetching data');
        console.error('Error fetching data:', err);
      }
    };

    if (
      props.selctedRFQInquiryId !== null &&
      props.selctedRFQInquiryId !== undefined &&
      props.selctedRFQInquiryId !== 'undefined'

    ) {
      if (fromIframe) {
        setOpenR2ADialog(true);
        getRFQToAuctionData();

      }
      if (props.openR2ADialog) {
        getRFQToAuctionData();
      }
    }
  }, [props.selctedRFQInquiryId, props.auctionR2AId, props.openR2ADialog]);

  const handleChangeR2AReportDropDown = (event) => {
    const selectedValue = event.target.value;
    const selectedId = parseInt(selectedValue.id, 10);
    setSelectedOption({ name: selectedValue.name, id: selectedValue.id });
    const matchedObject = r2aData.find((item) => item.id == selectedId);
    setAuctionDetail(matchedObject);
  };


  const sessionID = props.sessionID;
  // console.log("sessionID", props.sessionID);
  // const sessionID = props.session_id;


  React.useEffect(() => {
    window.$ = $;
    window.jQuery = jQuery;
    window.jq = jQuery;
  }, []);

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` }
  };

  React.useEffect(() => {
    dispatch(setUserInfo(sessionID));
  }, []);

  React.useEffect(() => {
    if (!props.openR2ADialog) {
      setsortingData("descending")
      setSelectedOption({});
    }

  }, [props.openR2ADialog])

  // State in parent to hold data from child
  // Function to handle data passed from the child for sorting dropdown
  const handleChildData = (data) => {
    setsortingData(data);
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleCloseOpenR2A = () => {
    setOpenR2ADialog(false);
    setsortingData("descending")
    if (fromIframe) {
      // console.log('fromIframe', "fromIframefromIframefromIframefromIframefromIframe");
      window.parent.postMessage("R2a_close_iframe", "*");
    }
  };

  //End Master Details 

  // End R2A Reports Dialog script

  return (
    <React.Fragment>

      {/* Start R2A Reports Dialog */}
      {/* <Dialog open={props.openR2ADialog} onClose={props.handleCloseOpenR2A} fullScreen sx={{ m: 4 }}> */}

      <Dialog open={openR2ADialog || props.openR2ADialog}
        onClose={() => {
          if (fromIframe) {
            handleCloseOpenR2A()
          } else {
            props.handleCloseOpenR2A()
            setsortingData("descending")
          }
        }}
        fullScreen sx={{ m: fromIframe ? 0 : { xs: 0, sm: 0, md: 4, lg: 4 }, }}
      >
        {/* first select dropdown box header */}
        <R2aDropDownDetailsHeader
          selectedOption={selectedOption}
          handleChangeR2AReportDropDown={handleChangeR2AReportDropDown}
          r2aDataGet={r2aData} mainR2aResponse={mainR2aResponse}
          auctionDetail={auctionDetail} sessionID={sessionID}
          selctedRFQInquiryId={props.selctedRFQInquiryId}
          handleCloseOpenR2A={props.handleCloseOpenR2A || handleCloseOpenR2A} />
        {/* End first select dropdown box header*/}
        <Divider />
        <DialogContent sx={{ p: 0 }}>
          {/*Secound box detail*/}
          <Box display="flex" alignItems="center" gap="16px" sx={{ background: "var(--grey-50)", padding: "4px 16px" }}>
            <Typography variant="subtitle1" fontWeight={500} color="grey.900">Details</Typography>
            {/* <Button variant="contained" color="success" size="small" sx={{ lineHeight: "normal", fontWeight: "400" }}>{auctionDetail.status}</Button> */}
            <Chip label={auctionDetail?.status} color="success" size="small" />
            <ExpandMore expand={expandedR2ADetails} onClick={() => setExpandedR2ADetails(!expandedR2ADetails)} aria-label="show more" size="small">
              <ArrowDropDownIcon />
            </ExpandMore>
          </Box>
          <Divider />
          {/* Added component */}
          <R2aAuctionDetails expandedR2ADetails={expandedR2ADetails}
            auctionDetail={auctionDetail}
            mainR2aResponse={mainR2aResponse} expandedR2ADetailsState={expandedR2ADetailsState} />

          {/*End Secound box detail*/}

          {(auctionDetail?.rfq_stage == 60 || auctionDetail?.rfq_stage == 70) && auctionDetail?.auction_category != 30 ? (
            <>
              {/* Third Graph detail */}
              <Box display="flex" alignItems="center" gap="16px" sx={{ background: "var(--grey-50)", padding: "4px 16px" }}>
                <Typography variant="subtitle1" fontWeight={500} color="grey.900">View Chart</Typography>
                <ExpandMore expand={expandedR2AChart} onClick={() => setExpandedR2AChart(!expandedR2AChart)} aria-expanded={expandedR2AChart} aria-label="show more" size="small">
                  <ArrowDropDownIcon />
                </ExpandMore>
              </Box>

              <R2AReportGraph expandedR2AChart={expandedR2AChart}
                // viewChartTabs={viewChartTabs} 
                //   ViewChartChange={ViewChartChange}
                mainR2aResponse={mainR2aResponse}
                auctionDetailforGraph={auctionDetail}
                onDataChange={handleChildData}
              />
              {/* End Third Graph detail */}

              {/* Fourth Table Grid */}
              <R2aAuctionTableSection
                //  r2aData={r2aData}
                rfq_vendors={mainR2aResponse.rfq_vendors}
                auctionDetail={auctionDetail} sortingData={sortingData} />
              {/* End Fourth Table Grid */}
            </>
          ) : (
            <>
              {/* Else part content */}
              <Box
                maxWidth={"100%"}
                textAlign={"center"}
                pl={3}
                pr={3}
                pt={5}
                pb={5}
              >

                {auctionDetail?.auction_category == 30 ? (
                  <>
                    <ErrorOutlineIcon sx={{ fontSize: "54px", color: "text.primary" }} />
                  </>
                ) : (
                  <EmptyMsgIcon sx={{ maxWidth: "225px", width: "100%", height: "auto", mb: 1 }} />
                )}

                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  fontWeight={400}
                  lineHeight="normal"
                >
                  {auctionDetail?.rfq_stage == 50 ? (
                    <>
                      RFQ to Auction is ongoing. <br />
                    </>
                  ) : null}

                  {auctionDetail?.auction_category == 30 ? (
                    <>
                      No data available. <br />
                      It looks like there's nothing to show here right now.
                    </>
                  ) : (
                    <>Please try again after the completion of the auction.</>
                  )}

                </Typography>
              </Box>
            </>
          )}

        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
export default R2aReportComponent;
