import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import {
  ClearTwoTone,
  CancelOutlined,
  PersonAddAltOutlined,
  StarBorderOutlined,
  GroupAddOutlined,
  Layers,
} from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Paper,
  Select,
  Snackbar,
  Switch,
  TextField,
  TextareaAutosize,
  Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import R2AReport from "../../assets/RFQList/images/r2a_report.png";
import MultilineSolution from "../../assets/RFQList/images/multiple_solution.png";
import {
  AddDatePicker,
  ExtendValidityThread,
} from "../../components/SvgIcon/SvgIcon";
import { CaseStudyIcon } from "../../components/SvgIcon/SvgIcon";
import { R2AReportsIcon } from "../../components/SvgIcon/SvgIcon";
import { MultipleSolutionIcon } from "../../components/SvgIcon/SvgIcon";
import JustificationDialog from "../../common/RFQList/justificationModal";
import LinkUnlinkUser from "../../components/RFQList/linkUnlinkUser/linkUnlinkUser";
import axios from "axios";
import { redirect } from "react-router-dom";
import {
  extend_validity,
  extend_validity_thread,
  get_Inquiries_data,
  inquiry_logs,
  multiple_solution,
  prviewTB,
  r2a_reports,
  send_RFQ_to_new_vendor,
  getGrfqDetails,
  pr_status_integration
} from "../../config/apiUrl";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setRfqListInfo } from "../../redux/actions/rfqListAction";
import InnerHTML from "dangerously-set-html-content";
import $ from "jquery";
import jQuery from "jquery";
// import DataTable from "datatables.net";
import * as Constants from "../../constant/RFQList/constant";
import { URL_MAPPING } from "../../config/host-mapping";
import { OPEN_SNACKBAR } from "../../redux/constants/type";
import { setUserInfo } from "../../redux/actions/setUserInfo";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import R2aReportComponent from "../../components/R2aReport/R2aReportComponent";
import RfqDataGrid from "../../pages/RFQList/rfqGridView/rfqDataGrid";
const currentHost = window.location.host;
const parentHostURI =
  URL_MAPPING[currentHost]["parent_host"] ||
  URL_MAPPING["default"]["parent_host"];

export function loadScripts(sources) {
  sources.forEach((src) => {
    var script = document.createElement("script");
    script.src = src;
    script.async = false; //<-- the important part
    document.body.appendChild(script); //<-- make sure to append to body instead of head
  });
}
export function loadStyle(sources) {
  sources.forEach((src) => {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", src);
    document.head.appendChild(linkElement);
  });
}

export function removeJSFile(filename, filetype) {
  var targetelement =
    filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist from
  var targetattr =
    filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
  var allsuspects = document.getElementsByTagName(targetelement);
  for (var i = allsuspects.length; i >= 0; i--) {
    //search backwards within nodelist for matching elements to remove
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute(targetattr) != null &&
      allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
    )
      allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
  }
}

const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: "100%", // Set the desired width
    height: "20%", // Set the desired height
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  typography1: {
    display: "inline",
    marginRight: theme.spacing(1),
  },
  RFQMenuStyle: {
    "& .css-jwi4z7-MuiButtonBase-root-MuiMenuItem-root": {
      minHeight: "auto",
      // marginRight: "57px",
    },
  },
}));

export default function RFQMenuItems({
  sessionID,
  menulabels,
  openMenu,
  handleClose,
  anchorEl,
  categoryId,
  r2aId,
  userData,
  linkedUserId,
  // createdBy,
  linkDataFlag,
  isPriortized,
  linkUnlinkData,
  InquiryData
}) {
  const drawerWidth = 400;
  const classes = useStyles();
  const dispatch = useDispatch();
  const RFQListData = useSelector((state) => state.rfqListReducer.rfqListInfo);
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);

  const Permisison = useSelector((state) => state.permissionsReducer)
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [R2AReports, setR2AReports] = React.useState({ __html: "" });
  const [inquiryLogs, setInquiryLogs] = React.useState({ __html: "" });
  const [extendValidityHtml, setExtendValidityHtml] = React.useState({
    __html: "",
  });
  const [extendValidityThread, setExtendValidityThread] = React.useState({
    __html: "",
  });
  const [Tbpreview, setTbpreview] = React.useState({
    __html: "",
  });
  const [multipleSolution, setMultipleSolution] = React.useState({
    __html: "",
  });
  const [sendRfqToNewVendor, setSendRfqToNewVendor] = React.useState({
    __html: "",
  });
  const [openHtml, setOpenHtml] = React.useState(false);
  const [openHtmlGrpNotCreated, setOpenHtmlGrpNotCreated] = React.useState(false);
  const [prioritySnackbar, setPrioritySnackbar] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [severity, setseverity] = React.useState('')
  const [SnackMessage, setSnackMessage] = React.useState('')
  const [loader, setLoader] = React.useState(true);
  const [anchorEl1, setAnchorEl1] = React.useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(false);
  const [anchorEl3, setAnchorEl3] = React.useState(false);
  const [anchorElExtendValidityThread, setAnchorElExtendValidityThread] =
    React.useState(false);
  const [anchorElTB, setAnchorElTB] =
    React.useState(false);
  // const [justification, setJustification] = React.useState("");
  const [openDialogueBox, setOpenDialogueBox] = React.useState(false);
  const [openPriorityModal, setOpenPriorityModal] = React.useState(false);
  const [multipleSolutionAnchor, setMultipleSolutionAnchor] =
    React.useState(false);
  const [openJustificationModal, setOpenJustificationModal] =
    React.useState(false);
  const [formData, setFormData] = React.useState({
    // Initialize your form fields with default values or leave them empty.
    // For example:
    inquiryId: "",
    actionCode: "",
    callingFrom: "",
    // Add other fields as needed.
  });

  const configAxiosPost = {
    headers: { Authorization: `Bearer ${sessionID}` },
    "Content-Type": "application/json",
  };

  const configAxiosGet = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };

  React.useEffect(() => {
    window.$ = $;
    window.jQuery = jQuery;
    window.jq = jQuery;
  }, []);

  const inquiry_id = linkUnlinkData?.inquiryNo;

  const handlePriorityModalOpen = () => {
    setOpenPriorityModal(true);
  };

  const handleJustificationModalOpen = () => {
    setOpenJustificationModal(true);
  };

  const handleClosePrioritySnackbar = () => {
    setPrioritySnackbar(false);
  };

  const handlePriorityModalClose = () => {
    setOpenPriorityModal(false);
  };

  const handleExtendValidityModalClose = () => {
    setExtendValidityHtml({ __html: "" });
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));

    setOpenHtml(false);
    setOpenHtmlGrpNotCreated(false);
    removeJSFile(parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js", "js");
    removeJSFile(parentHostURI + "/js/check_validation.js", "js");
    removeJSFile(parentHostURI + "/js/extranet_compose.js", "js");
    removeJSFile(parentHostURI + "/js/ui.js", "js");
    removeJSFile(parentHostURI + "/js/datepicker.js", "js");
    removeJSFile(parentHostURI + "/js/Widget.js", "js");
    removeJSFile(parentHostURI + "/plugins/anytimec/anytimec.js", "js");
    removeJSFile(parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js", "js");
    removeJSFile(parentHostURI + "/js/vendx_common.js", "js");

    jQuery("body").find('#AnyTime--datepicker').remove();
    jQuery("body").find('#ui-datepicker-div').remove();

  };

  const handleSendRfqToNewVendorClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));
    setAnchorEl1(false);
    setSendRfqToNewVendor({ __html: "" });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const handleR2AReportsClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));
    setAnchorEl2(false);
    setR2AReports({ __html: "" });
  };

  const handleExtendValidityThreadClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));
    setAnchorElExtendValidityThread(false);
    setExtendValidityThread({ __html: "" });
  };

  const handleTbClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));
    setAnchorElTB(false);
    setTbpreview({ __html: "" });
  };

  const handleInquiryLogsClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));
    setInquiryLogs({ __html: "" });
    setAnchorEl3(false);
    removeJSFile(
      parentHostURI + "/plugins/datatables/jquery.dataTables.js",
      "js"
    );
    removeJSFile(
      parentHostURI + "/plugins/datatables/dataTables.bootstrap.min.js",
      "js"
    );
    removeJSFile(parentHostURI + "/js/vendx_common.js", "js");
    removeJSFile(
      parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
      "js"
    );
  };

  const handleMultipleSolutionClose = () => {
    // window.location.reload()
    dispatch(setRfqListInfo(sessionID));
    setMultipleSolutionAnchor(false);
    setMultipleSolution({ __html: "" });
    removeJSFile(parentHostURI + '/js/masters/jquery_grid/jquery-1.7.2.min.js', 'js')
    removeJSFile(parentHostURI + '/js/masters/jquery_grid/jquery.multiselect.min.js', 'js')
    removeJSFile(parentHostURI + '/js/Multiple_solutions/export_multiple_solution.js', 'js')
  };

  const handleJustificationModalClose = () => {
    setOpenJustificationModal(false);
  };

  const OpenMultipleSolutionDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleJustificationModalSubmit = (justificationText) => {
    const url =
      get_Inquiries_data + `${linkUnlinkData.inquiryNo}/close_inquiry`;
    axios
      .patch(url, { justification: justificationText }, configAxiosGet)
      .then((response) => {
        if (response.status === 200) {
          handleJustificationModalClose();
          setSnackbar(true);
          setseverity("success")
          setSnackMessage("Close RFQ Requested Successfully")
          dispatch(setRfqListInfo(sessionID)); //Test purpose only Has to change this Only Rejected RFQ List is coming
          if (
            Permisison.accountModulePermission['PR Status Integration']?.module_activated === "Y"
          ) {
              axios
                .get(
                  pr_status_integration(
                    userInfo?.division_id,
                    userInfo?.company_id,
                    InquiryData.id,
                    // pr_ids_array.join()
                  )
                )
                .then((response) => {
                  console.log('Successfully rfq closed');
                })
                .catch((err) => {
                  //alert("error");
                });
          }
        } else {
          console.error(
            `PATCH request failed with status code ${response.status}.`
          );

        }
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
        if (error.response.data.code == 500) {
          setSnackbar(true);
          setseverity('error')
          setSnackMessage(error?.response?.data?.message ? error.response.data.message : "Somwthing went wrong.")
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: error.response.data.message,
          //   },
          // });
        }
      });
    handleJustificationModalClose();
  };

  const handlePriorityModalSubmit = (justification) => {
    const url =
      get_Inquiries_data + `${linkUnlinkData.inquiryNo}/priority_non_priority`;

    axios
      .patch(
        url,
        { is_priority: !isPriortized, justification: justification },
        configAxiosGet
      )
      .then((response) => {
        if (response.status === 200) {
          setPrioritySnackbar(true);
          dispatch(setRfqListInfo(sessionID)); //Test purpose only Has to change this Only Rejected RFQ List is coming
        } else {
          console.error(
            `PATCH request failed with status code ${response.status}.`
          );
        }
      })
      .catch((error) => {
        console.error(`Error: ${error.message}`);
      });
    handlePriorityModalClose();
  };

  const handleOpenDialogue = (params) => {
    setOpenDialogueBox(true);
  };

  const handleCloseDialogue = () => {
    setOpenDialogueBox(false);
  };

  function loadExtendValidityJs() {
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/js/jquery-1.6.2.js",
      parentHostURI + "/js/ui.js",
      parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
      parentHostURI + "/js/check_validation.js",
      parentHostURI + "/js/extranet_compose.js",
      parentHostURI + "/plugins/anytimec/anytimec.js",
      parentHostURI + "/js/datepicker.js",
      parentHostURI + "/js/Widget.js",
      parentHostURI + "/js/vendx_common.js",
    ]);
  }
  const handleExtendValidity = async () => {
    setLoader(true);
    const grfq_data = await axios.get(getGrfqDetails(inquiry_id), configAxiosGet);    
    const grfq_details = grfq_data.data.grfq_details;

    if (
      grfq_details.group_rfq_id == 0 && 
      grfq_details.group_rfq_random_number != '' && 
      grfq_details.group_rfq_random_number != null &&
      grfq_details.group_rfq_random_number != 10 && 
      grfq_details.rfq_status == 10
    ) {
      setOpenHtmlGrpNotCreated(true);
      setLoader(false);
    } else {
      
      const queryParams = new URLSearchParams({
        vendx_action: Constants.vendx_action,
        TType: Constants.t_type,
        SelectAutoTriggerInquiry: inquiry_id,
        productid: categoryId,
        master_type: Constants.master_type,
        resend_from_qplus: Constants.resend_from_qplus,
        qcs_plus: Constants.qcs_plus,
      });
      const url = `${extend_validity}?${queryParams}`;
      setLoader(true);
      setOpenHtml(true);
      try {
        const response = await axios.get(url, configAxiosGet);
        if (response) {
          setExtendValidityHtml(response.data);
          setLoader(false);
        } else {
          // Handle empty data case
          removeJSFile(parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js", "js");
          removeJSFile(parentHostURI + "/js/jquery-1.6.2.js", "js");
          removeJSFile(parentHostURI + "/js/ui.js", "js");
          removeJSFile(parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js", "js");
          removeJSFile(parentHostURI + "/js/check_validation.js", "js");
          removeJSFile(parentHostURI + "/js/extranet_compose.js", "js");
          removeJSFile(parentHostURI + "/plugins/anytimec/anytimec.js", "js");
          removeJSFile(parentHostURI + "/js/datepicker.js", "js");
          removeJSFile(parentHostURI + "/js/Widget.js", "js");
          removeJSFile(parentHostURI + "/js/vendx_common.js", "js");
        }
      } catch (error) {
        removeJSFile(parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js", "js");
        removeJSFile(parentHostURI + "/js/jquery-1.6.2.js", "js");
        removeJSFile(parentHostURI + "/js/ui.js", "js");
        removeJSFile(parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js", "js");
        removeJSFile(parentHostURI + "/js/check_validation.js", "js");
        removeJSFile(parentHostURI + "/js/extranet_compose.js", "js");
        removeJSFile(parentHostURI + "/plugins/anytimec/anytimec.js", "js");
        removeJSFile(parentHostURI + "/js/datepicker.js", "js");
        removeJSFile(parentHostURI + "/js/Widget.js", "js");
        removeJSFile(parentHostURI + "/js/vendx_common.js", "js");
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleSendRfqToNewVendor = async () => {
    const queryParams = new URLSearchParams({
      inq_id: inquiry_id,
      buyerid: categoryId,
      action: Constants.send_RFQ_to_new_vendor,
    });
    const url = `${send_RFQ_to_new_vendor}?${queryParams}`;
    setAnchorEl1(true);
    setLoader(true);
    loadStyle([
      parentHostURI + "/jquery_grid/css/ui.jqgrid.css",
      parentHostURI + "/jquery_grid/css/ui.multiselect.css",
      parentHostURI + "/jquery_grid/css/anytimec.css",
      parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
      parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
      parentHostURI + "/css/bluestar_blue.css",
      parentHostURI + "/css/ajax-dynamic-list.css",
      parentHostURI + "/css/Multiple_Solutions/multiple_solutions.css",
      parentHostURI + "/jquery_grid/css/jquery.loader.css",
      parentHostURI + "/css/ui-kits/number-align.css",
      parentHostURI + "/css/masters/jquery.alerts.css",
      parentHostURI + "/css/Multiple_Solutions/jAlert.css",
      parentHostURI + "/css/masters/new_masters.css",
      parentHostURI + "/css/masters/vendorform.css",
    ]);
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/js/masters/jquery_grid/jquery-ui-1.8.20.custom.min.js",
      parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
      parentHostURI + "/js/masters/jquery_grid/jquery.alerts.js",
      parentHostURI + "/jquery_grid/javascript/jquery.layout.js",
      parentHostURI + "/jquery_grid/javascript/i18n/grid.locale-en.js",
      parentHostURI + "/jquery_grid/javascript/jquery.jqGrid.min.js",
      parentHostURI + "/js/masters/masters_linking.js",
      parentHostURI + "/js/masters/masters_auto_minimized.js",
      parentHostURI + "/item_vendor_linking/js/ivl_actions.js",
      parentHostURI + "/js/new_js/rfq_detail_update.js",
      parentHostURI + "/js/Multiple_solutions/jAlert.min.js",
      parentHostURI + "/js/masters/jquery_grid/jquery.alerts.js",
      parentHostURI + "/js/masters/colorbox_js/jquery.colorbox.js",
      parentHostURI + "/js/CommonVendor/common_vendor.js",
      parentHostURI + "/item_vendor_linking/js/ivl_actions.js",
      parentHostURI + "/item_vendor_linking/js/ivl_rfq.js",

    ]);
    try {
      const response = await axios.get(url, configAxiosGet);
      if (response) {
        setSendRfqToNewVendor(response?.data);
        setLoader(false);
      } else {
        setLoader(false);
        removeJSFile(parentHostURI + "/jquery_grid/css/ui.jqgrid.css", "css");
        removeJSFile(
          parentHostURI + "/jquery_grid/css/ui.multiselect.css",
          "css"
        );
        removeJSFile(parentHostURI + "/jquery_grid/css/anytimec.css", "css");
        removeJSFile(
          parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
          "css"
        );
        removeJSFile(
          parentHostURI + "/jquery_grid/css/jquery.loader.css",
          "css"
        );
        removeJSFile(parentHostURI + "/css/ui-kits/number-align.css", "css");
        removeJSFile(parentHostURI + "/css/masters/jquery.alerts.css", "css");
        removeJSFile(parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css", "css");
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
        removeJSFile(parentHostURI + "/css/ajax-dynamic-list.css", "css");
        removeJSFile(parentHostURI + "/css/Multiple_Solutions/multiple_solutions.css", "css");
        removeJSFile(parentHostURI + "/css/masters/new_masters.css", "css");
        removeJSFile(parentHostURI + "/css/masters/vendorform.css", "css");
        removeJSFile(
          parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
          "js"
        );
        removeJSFile(
          parentHostURI +
          "/js/masters/jquery_grid/jquery-ui-1.8.20.custom.min.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/jquery_grid/javascript/jquery.layout.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/jquery_grid/javascript/i18n/grid.locale-en.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/jquery_grid/javascript/jquery.jqGrid.min.js",
          "js"
        );
        removeJSFile(parentHostURI + "/js/masters/masters_linking.js", "js");
        removeJSFile(
          parentHostURI + "/js/masters/masters_auto_minimized.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/item_vendor_linking/js/ivl_actions.js",
          "js"
        );
        removeJSFile(parentHostURI + "/js/new_js/rfq_detail_update.js", "js");
        removeJSFile(
          parentHostURI + "/js/masters/jquery_grid/jquery.alerts.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/js/masters/colorbox_js/jquery.colorbox.js",
          "js"
        );
        removeJSFile(parentHostURI + "/js/CommonVendor/common_vendor.js", "js");
        removeJSFile(
          parentHostURI + "/item_vendor_linking/js/ivl_actions.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/item_vendor_linking/js/ivl_rfq.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
          "js"
        );
        // Handle empty data case
      }
    } catch (error) {
      setLoader(false);
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      console.error("Error fetching data:", error);
    }
  };

  const handleInquiryLogs = async () => {
    const formData = new FormData();
    formData.append("inquiry_id", inquiry_id);
    formData.append("action", Constants.view_Inquiry_Logs_Action);
    // formData.append("session_id", "1696226635229250.127161743954584"); // Replace with your session ID

    setLoader(true);
    setAnchorEl3(true);
    loadStyle([
      parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
      parentHostURI + "/css/bluestar_blue.css",
    ]);
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/plugins/datatables/jquery.dataTables.js",
      parentHostURI + "/plugins/datatables/dataTables.bootstrap.min.js",
      parentHostURI + "/js/vendx_common.js",
      parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
    ]);
    try {
      const response = await axios.post(
        inquiry_logs,
        formData,
        configAxiosPost
      );
      if (response) {
        setInquiryLogs(response.data.html);
        setLoader(false);
      } else {
        // Handle empty data case
        removeJSFile(
          parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/plugins/datatables/jquery.dataTables.js",
          "js"
        );
        removeJSFile(
          parentHostURI + "/plugins/datatables/dataTables.bootstrap.min.js",
          "js"
        );
        removeJSFile(parentHostURI + "/js/vendx_common.js", "js");
        removeJSFile(
          parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
          "js"
        );
        removeJSFile(parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css", "css");
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      removeJSFile(
        parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
        "js"
      );
      removeJSFile(
        parentHostURI + "/plugins/datatables/jquery.dataTables.js",
        "js"
      );
      removeJSFile(
        parentHostURI + "/plugins/datatables/dataTables.bootstrap.min.js",
        "js"
      );
      removeJSFile(parentHostURI + "/js/vendx_common.js", "js");
      removeJSFile(
        parentHostURI + "/jquery_grid/javascript/jquery.loader-0.3.js",
        "js"
      );
      removeJSFile(parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css", "css");
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
    }
  };

  const [openR2ADialog, setOpenR2ADialog] = React.useState(false);

  // const getRFQToAuctionData = async () => {
  //   try {
  //     let url = getR2aDetails(3232, 303);
  //     const response = await axios.get(url, configAxios);
  //     setR2aData(response.data.r2as);
  //     setMainR2aResponse(response.data)
  //     setSelectedOption({ name: response.data.r2as[0].number, id: response.data.r2as[0].id });
  //     const matchedObjects = response.data.r2as.find((item) => item.id === response.data.r2as[0].id);
  //     setAuctionDetail(matchedObjects);
  //   } catch (err) {
  //     setError('Error fetching data');
  //     console.error('Error fetching data:', err);
  //   }
  // };

  const handleOpenR2A = () => {
    setOpenR2ADialog(true);
  };

  const handleCloseOpenR2A = () => {
    setOpenR2ADialog(false);
  };


  // const handleR2AReports = async () => {
  //   const formData = new FormData();
  //   formData.append("inquiry_id", inquiry_id);
  //   formData.append("action", Constants.r2a_Action);
  //   formData.append("action_code", Constants.action_code);
  //   formData.append("calling_from", Constants.calling_from);
  //   formData.append("auction_rfq_id", r2aId);
  //   formData.append("selected_auction_rfq_id", r2aId);
  //   formData.append("r2a_view_type", Constants.r2a_view_type);
  //   formData.append("seal_bid_error_flag", Constants.seal_bid_error_flag);
  //   loadStyle([
  //     parentHostURI + "/css/masters/new_masters.css",
  //     parentHostURI + "/css/bluestar_blue.css",
  //     parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
  //   ]);
  //   loadScripts([
  //     parentHostURI + '/js/Multiple_solutions/jquery-1.11.3.min.js',
  //     parentHostURI + '/js/masters/jquery_grid/jquery-ui-1.8.20.custom.min.js',
  //     parentHostURI + '/r2a/js/r2a_report.js',
  //     parentHostURI + "/js/amcharts/amcharts.js",
  //     parentHostURI + "/js/amcharts/exporting/export.js",
  //   ]);
  //   setAnchorEl2(true);
  //   setLoader(true);
  //   try {
  //     const response = await axios.post(r2a_reports, formData);
  //     if (response) {
  //       setR2AReports(response.data);
  //       setLoader(false);
  //     } else {
  //       // Handle empty data case
  //       removeJSFile(parentHostURI + "/css/masters/new_masters.css", "css");
  //       removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
  //       removeJSFile(parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css", "css");
  //     }
  //   } catch (error) {
  //     removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const handleExtendValidityThread = async () => {
    const formData = new FormData();
    formData.append("inquiry_id", inquiry_id);
    formData.append("action", Constants.extend_validy_thread_details);
    loadStyle([
      parentHostURI + "/css/bluestar_blue.css",
    ]);
    loadScripts([parentHostURI + "/js/Multiple_solutions/jAlert.js"]);
    setAnchorElExtendValidityThread(true);
    setLoader(true);
    try {
      const response = await axios.post(extend_validity_thread, formData);
      if (response) {
        setExtendValidityThread(response.data);
        setLoader(false);
      } else {
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
        // Handle empty data case
      }
    } catch (error) {
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      console.error("Error fetching data:", error);
    }
  };

  const handleTBPreview = async () => {
    const formData = new FormData();
    formData.append("from_react", "1");
    formData.append("inquiry_id", inquiry_id);
    formData.append("tran_type_guid", InquiryData?.vendor?.guid);
    formData.append("tb_master_type", "category");
    formData.append("product_category_name", InquiryData?.inquiry_categories?.name);
    formData.append("product_id", InquiryData?.inquiry_categories?.id);
    formData.append("master_action", 'technical_bid');
    formData.append("oper", 'vendor_preview');
    formData.append("vendor_flag", '1');

    formData.append("pr_flag", '1');
    // formData.append("rfq_mapping_type", '10');//need to discuss

    loadStyle([
      parentHostURI + "/plugins/font-awesome-4.7.0/css/font-awesome.min.css",
      parentHostURI + "/css/ajax-dynamic-list.css",
      parentHostURI + "/pr/bootstrap/css/bootstrap-toggle.min.css",
      parentHostURI + "/css/bluestar_blue.css",
      parentHostURI + "/css/masters/admin-jquery-ui.min.css",
      parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
      parentHostURI + "/css/masters/new_masters.css",
      parentHostURI + "/css/masters/technical_bid_template.css",
      parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
      parentHostURI + "/css/ui-kits/number-align.css",
      parentHostURI + "/css/ui-kits/react-custom.css",
    ]);
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/js/masters/masters_auto_minimized.js"
    ]);
    setAnchorElTB(true);
    setLoader(true);
    try {
      const response = await axios.post(prviewTB, formData);
      if (response) {
        console.log('response', response);
        setTbpreview(response.data);
        setLoader(false);
      } else {
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
        // Handle empty data case
      }
    } catch (error) {
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      console.error("Error fetching data:", error);
    }
    // console.log('clicled');
  }

  const handleMultipleSolution = async () => {
    const formData = new FormData();
    formData.append("inquiry_id", inquiry_id);
    formData.append("action", Constants.multiple_solution_action);
    formData.append("ms_view_caller", Constants.ms_view_caller);
    loadStyle([
      parentHostURI + "/css/Multiple_Solutions/bootstrap-dynamic-tabs.css",
    ]);
    loadScripts([
      //parentHostURI + '/js/masters/jquery_grid/jquery-1.7.2.min.js',
      parentHostURI + '/js/Multiple_solutions/jquery-1.11.3.min.js',
      parentHostURI + '/js/masters/jquery_grid/jquery-ui-1.8.20.custom.min.js',
      parentHostURI + '/js/Multiple_solutions/bootstrap.min.js',
      parentHostURI + '/js/Multiple_solutions/jAlert.js',
      parentHostURI + '/js/Multiple_solutions/bootstrap-dynamic-tabs.js',
      parentHostURI + '/js/masters/masters_auto_minimized.js',
      parentHostURI + '/jquery_grid/javascript/ui.multiselect.js',
      parentHostURI + '/js/masters/jquery_grid/jquery.multiselect.min.js',
      parentHostURI + '/js/Multiple_solutions/multiple_solutions.js',
      parentHostURI + '/js/Multiple_solutions/export_multiple_solution.js',
      parentHostURI + '/js/Widget.js',

    ])
    setMultipleSolutionAnchor(true);
    setLoader(true);
    try {
      const response = await axios.post(
        multiple_solution,
        formData,
        configAxiosPost
      );
      if (response) {
        setMultipleSolution(response.data);
        setLoader(false);
      } else {
        removeJSFile(parentHostURI + "/css/Multiple_Solutions/bootstrap-dynamic-tabs.css", "css")
        removeJSFile(parentHostURI + '/js/Multiple_solutions/jquery-1.11.3.min.js', "js")
        removeJSFile(parentHostURI + '/js/masters/jquery_grid/jquery-ui-1.8.20.custom.min.js', "js")
        removeJSFile(parentHostURI + '/js/Multiple_solutions/bootstrap.min.js', "js")
        removeJSFile(parentHostURI + '/js/Multiple_solutions/jAlert.js', "js")
        removeJSFile(parentHostURI + '/js/Multiple_solutions/bootstrap-dynamic-tabs.js', "js")
        removeJSFile(parentHostURI + '/js/masters/masters_auto_minimized.js', "js")
        removeJSFile(parentHostURI + '/jquery_grid/javascript/ui.multiselect.js', "js")
        removeJSFile(parentHostURI + '/js/masters/jquery_grid/jquery.multiselect.min.js', "js")
        removeJSFile(parentHostURI + '/js/Multiple_solutions/multiple_solutions.js', "js")
        removeJSFile(parentHostURI + '/js/Multiple_solutions/export_multiple_solution.js', "js")
        removeJSFile(parentHostURI + '/js/Widget.js', "js")

        // Handle empty data case
      }
    } catch (error) {
      removeJSFile(parentHostURI + "/css/Multiple_Solutions/bootstrap-dynamic-tabs.css", "css")
      removeJSFile(parentHostURI + '/js/Multiple_solutions/jquery-1.11.3.min.js', "js")
      removeJSFile(parentHostURI + '/js/masters/jquery_grid/jquery-ui-1.8.20.custom.min.js', "js")
      removeJSFile(parentHostURI + '/js/Multiple_solutions/bootstrap.min.js', "js")
      removeJSFile(parentHostURI + '/js/Multiple_solutions/jAlert.js', "js")
      removeJSFile(parentHostURI + '/js/Multiple_solutions/bootstrap-dynamic-tabs.js', "js")
      removeJSFile(parentHostURI + '/js/masters/masters_auto_minimized.js', "js")
      removeJSFile(parentHostURI + '/jquery_grid/javascript/ui.multiselect.js', "js")
      removeJSFile(parentHostURI + '/js/masters/jquery_grid/jquery.multiselect.min.js', "js")
      removeJSFile(parentHostURI + '/js/Multiple_solutions/multiple_solutions.js', "js")
      removeJSFile(parentHostURI + '/js/Multiple_solutions/export_multiple_solution.js', "js")
      removeJSFile(parentHostURI + '/js/Widget.js', "js")
      console.error("Error fetching data:", error);
    }
  };

  return (
    <React.Fragment>
      {openHtml == true ? loadExtendValidityJs() : ""}

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        fullWidth
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          "&.MuiPopover-root .MuiPaper-root": {
            minWidth: "315px",
            "& .MuiMenuItem-root .MuiListItemIcon-root": {
              minWidth: "40px",
            },
          },
        }}
        className={classes.RFQMenuStyle}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              mr: 1,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {InquiryData?.closed_at === null && (userInfo?.user_id == InquiryData?.created_by || userInfo?.is_super_user == "Y" || Permisison?.masterPermission?.extend_rfq == 'Y') && <MenuItem onClick={handleExtendValidity}>
          <ListItemIcon>
            <AddDatePicker />
          </ListItemIcon>
          {/* <ListItemText>Extend Validity to RFQ</ListItemText> */}
          <ListItemText>{menulabels.extend_validity_to_rfq}</ListItemText>
        </MenuItem>}
        {InquiryData?.closed_at === null && <MenuItem onClick={handleSendRfqToNewVendor}>
          <ListItemIcon>
            <PersonAddAltOutlined fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Send RFQ To New Vendor</ListItemText> */}
          <ListItemText>{menulabels.send_rfq_to_new_vendor}</ListItemText>
        </MenuItem>}
        {(Permisison?.accountModulePermission?.['Multiple Solutions']?.module_activated == 'Y' && InquiryData?.filter_data?.multiple_solution === true && InquiryData?.vendor_count?.quoted > 0) && (InquiryData?.filter_data?.sealed === false || InquiryData?.closed_at !== null || InquiryData?.status == 'Expired') && <MenuItem onClick={() => handleMultipleSolution()}>
          <ListItemIcon>
            <Layers />
          </ListItemIcon>
          {/* <ListItemText>View Multiple Solution</ListItemText> */}
          <ListItemText>{menulabels.view_multiple_solution}</ListItemText>
        </MenuItem>}
        {(userInfo?.user_id == InquiryData?.created_by || userInfo?.is_super_user == "Y") && <MenuItem onClick={handlePriorityModalOpen}>
          <ListItemIcon>
            <StarBorderOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {/* {isPriortized === true ? "Priority" : "Not Priority"} */}
            {isPriortized === true
              ? menulabels.not_priority
              : menulabels.priority}
          </ListItemText>
        </MenuItem>}
        <MenuItem onClick={(e) => handleInquiryLogs(e)}>
          <ListItemIcon>
            <CaseStudyIcon />
          </ListItemIcon>
          {/* <ListItemText>Inquiry Logs</ListItemText> */}
          <ListItemText>{menulabels.inquiry_logs}</ListItemText>
        </MenuItem>
        {(Permisison?.accountModulePermission?.['Transaction Access']?.module_activated == 'Y') && <MenuItem onClick={(e) => handleOpenDialogue(e)}>
          <ListItemIcon>
            <GroupAddOutlined fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Link/Unlink User</ListItemText> */}
          <ListItemText>{menulabels.link_unlink_user}</ListItemText>
        </MenuItem>}
        {/*<MenuItem onClick={handleClickPTB}>
          <ListItemIcon>
            <RemoveRedEyeOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Preview Technical Bid</ListItemText>
      </MenuItem>*/}
        {(InquiryData?.filter_data?.r2a === true && Permisison?.accountModulePermission?.['RFQ To Auction']?.module_activated == 'Y') && <MenuItem onClick={handleOpenR2A}>
          <ListItemIcon>
            <R2AReportsIcon />
          </ListItemIcon>
          {/* <ListItemText>R2A Reports</ListItemText> */}
          <ListItemText>{menulabels.r2a_reports}</ListItemText>
        </MenuItem>}
        {InquiryData?.closed_at === null && (userInfo?.user_id == InquiryData?.created_by || userInfo?.is_super_user == "Y" || Permisison?.masterPermission?.closing_rfq == 'Y') && <MenuItem onClick={handleJustificationModalOpen}>
          <ListItemIcon>
            <CancelOutlined fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Close RFQ</ListItemText> */}
          <ListItemText>{menulabels.close_rfq}</ListItemText>
        </MenuItem>}

        <MenuItem onClick={handleExtendValidityThread}>
          <ListItemIcon>
            <ExtendValidityThread fontSize="small" />
          </ListItemIcon>
          {/* <ListItemText>Extend Validity Thread</ListItemText> */}
          <ListItemText>{menulabels.extend_validity_thread}</ListItemText>
        </MenuItem>

        {Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" &&
          InquiryData?.valid_date_tb !== null &&
          <MenuItem onClick={handleTBPreview} >
            <ListItemIcon>
              <VisibilityOutlinedIcon fontSize="small" />
            </ListItemIcon>
            {/* <ListItemText>Technical Bid Preview</ListItemText> */}
            <ListItemText>{menulabels.technical_bid_preview}</ListItemText>
          </MenuItem>}
      </Menu>
      <JustificationDialog
        open={openJustificationModal}
        onClose={handleJustificationModalClose}
        title={linkUnlinkData?.inquiryName}
        subtitle="Justification (RFQ Closed)"
        floatedRfqUser={InquiryData?.created_by}
        Flag='Closed'
        onSubmit={handleJustificationModalSubmit}
        sessionID={sessionID}
      />
      <JustificationDialog
        open={openPriorityModal}
        onClose={handlePriorityModalClose}
        title={linkUnlinkData?.inquiryName}
        subtitle={
          isPriortized ? "Priority to Not Priority" : "Not Priority to Priority"
        }
        floatedRfqUser={InquiryData?.created_by}
        Flag='Priority'
        onSubmit={handlePriorityModalSubmit}
        sessionID={sessionID}
      />
      <LinkUnlinkUser
        sessionID={sessionID}
        openDialogueBox={openDialogueBox}
        handleCloseDialogue={handleCloseDialogue}
        userData={userData}
        linkUnlinkData={linkUnlinkData}
        linkDataFlag={linkDataFlag}
        rowSelectionModel={[linkUnlinkData?.inquiryNo]}
        SelctedRFQ={[linkUnlinkData?.inquiryNo]}
      />

      {/* <Dialog open={openR2ADialog} onClose={handleCloseOpenR2A} fullScreen sx={{ m: 4 }}> */}
        <R2aReportComponent
          sessionID={sessionID}
          openR2ADialog={openR2ADialog}
          handleCloseOpenR2A={handleCloseOpenR2A}
          selctedRFQInquiryId={inquiry_id}
          auctionR2AId={r2aId}
        />
      {/* </Dialog> */}
      {/* <RfqDataGrid
        sessionID={sessionID}
      /> */}
      {/* {loader && <CircularProgress />} */}

      {/* Extend Validity Date start */}
      <Dialog
        open={openHtml}
        onClose={handleExtendValidityModalClose}
        fullScreen tabIndex={0}
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.extend_validity_to_rfq}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleExtendValidityModalClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            // <div dangerouslySetInnerHTML={{ __html: html }}></div>
            <InnerHTML html={extendValidityHtml} className="RFQ-PerlData ExtendDate-Modal-Perl" />
          )}
        </DialogContent>
      </Dialog>

      {/* Extend Validity Date start if group not created */}  
      <Dialog
        open={openHtmlGrpNotCreated}
        onClose={handleExtendValidityModalClose}
        fullScreen tabIndex={0}
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.extend_validity_to_rfq}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleExtendValidityModalClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <Box height={'100%'} width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <Stack display={'flex'} alignItems={'center'} direction="column">
                <Box display={'flex'} alignItems={'center'}>
                  <svg width="167" height="97" viewBox="0 0 167 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M46 84C44.1435 84 42.363 83.2625 41.0503 81.9497C39.7375 80.637 39 78.8565 39 77C39 75.1435 39.7375 73.363 41.0503 72.0503C42.363 70.7375 44.1435 70 46 70H7C5.14348 70 3.36301 69.2625 2.05025 67.9497C0.737498 66.637 0 64.8565 0 63C0 61.1435 0.737498 59.363 2.05025 58.0503C3.36301 56.7375 5.14348 56 7 56H47C48.8565 56 50.637 55.2625 51.9497 53.9497C53.2625 52.637 54 50.8565 54 49C54 47.1435 53.2625 45.363 51.9497 44.0503C50.637 42.7375 48.8565 42 47 42H22C20.1435 42 18.363 41.2625 17.0503 39.9497C15.7375 38.637 15 36.8565 15 35C15 33.1435 15.7375 31.363 17.0503 30.0503C18.363 28.7375 20.1435 28 22 28H62C60.1435 28 58.363 27.2625 57.0503 25.9497C55.7375 24.637 55 22.8565 55 21C55 19.1435 55.7375 17.363 57.0503 16.0503C58.363 14.7375 60.1435 14 62 14H160C161.857 14 163.637 14.7375 164.95 16.0503C166.263 17.363 167 19.1435 167 21C167 22.8565 166.263 24.637 164.95 25.9497C163.637 27.2625 161.857 28 160 28H120C121.857 28 123.637 28.7375 124.95 30.0503C126.262 31.363 127 33.1435 127 35C127 36.8565 126.262 38.637 124.95 39.9497C123.637 41.2625 121.857 42 120 42H142C143.857 42 145.637 42.7375 146.95 44.0503C148.263 45.363 149 47.1435 149 49C149 50.8565 148.263 52.637 146.95 53.9497C145.637 55.2625 143.857 56 142 56H131.826C126.951 56 123 59.134 123 63C123 66.866 129 70 129 70C130.857 70 132.637 70.7375 133.95 72.0503C135.263 73.363 136 75.1435 136 77C136 78.8565 135.263 80.637 133.95 81.9497C132.637 83.2625 130.857 84 129 84H46ZM153 49C153 47.6155 153.411 46.2622 154.18 45.111C154.949 43.9599 156.042 43.0627 157.321 42.5328C158.6 42.003 160.008 41.8644 161.366 42.1345C162.723 42.4046 163.971 43.0713 164.95 44.0503C165.929 45.0292 166.595 46.2765 166.865 47.6344C167.136 48.9922 166.997 50.3997 166.467 51.6788C165.937 52.9579 165.04 54.0511 163.889 54.8203C162.738 55.5895 161.384 56 160 56C158.143 56 156.363 55.2625 155.05 53.9497C153.737 52.637 153 50.8565 153 49Z" fill="#F3F7FF"/>
                  <path d="M79.4584 68.708H87.5417V44.458H79.4584V68.708ZM83.5 36.3747C84.6452 36.3747 85.6051 35.9873 86.3797 35.2127C87.1544 34.438 87.5417 33.4781 87.5417 32.333C87.5417 31.1879 87.1544 30.228 86.3797 29.4533C85.6051 28.6787 84.6452 28.2913 83.5 28.2913C82.3549 28.2913 81.395 28.6787 80.6204 29.4533C79.8457 30.228 79.4584 31.1879 79.4584 32.333C79.4584 33.4781 79.8457 34.438 80.6204 35.2127C81.395 35.9873 82.3549 36.3747 83.5 36.3747ZM83.5 88.9163C77.9091 88.9163 72.6549 87.8554 67.7375 85.7335C62.8202 83.6117 58.5427 80.732 54.9052 77.0945C51.2677 73.457 48.3881 69.1795 46.2662 64.2622C44.1443 59.3448 43.0834 54.0906 43.0834 48.4997C43.0834 42.9087 44.1443 37.6545 46.2662 32.7372C48.3881 27.8198 51.2677 23.5424 54.9052 19.9049C58.5427 16.2674 62.8202 13.3877 67.7375 11.2658C72.6549 9.14395 77.9091 8.08301 83.5 8.08301C89.091 8.08301 94.3452 9.14395 99.2625 11.2658C104.18 13.3877 108.457 16.2674 112.095 19.9049C115.732 23.5424 118.612 27.8198 120.734 32.7372C122.856 37.6545 123.917 42.9087 123.917 48.4997C123.917 54.0906 122.856 59.3448 120.734 64.2622C118.612 69.1795 115.732 73.457 112.095 77.0945C108.457 80.732 104.18 83.6117 99.2625 85.7335C94.3452 87.8554 89.091 88.9163 83.5 88.9163ZM83.5 80.833C92.5264 80.833 100.172 77.7007 106.436 71.4361C112.701 65.1715 115.833 57.5261 115.833 48.4997C115.833 39.4733 112.701 31.8278 106.436 25.5632C100.172 19.2986 92.5264 16.1663 83.5 16.1663C74.4737 16.1663 66.8282 19.2986 60.5636 25.5632C54.299 31.8278 51.1667 39.4733 51.1667 48.4997C51.1667 57.5261 54.299 65.1715 60.5636 71.4361C66.8282 77.7007 74.4737 80.833 83.5 80.833Z" fill="#F4BE0E"/>
                  </svg>
                </Box>
                <Typography 
                  color={'text.primary'} 
                  fontWeight={500} 
                  fontSize={'20px'}
                >
                  Validity cannot be changed
                </Typography>
                <Typography 
                  color={'text.primary'}
                >
                  since the group has not been created yet.
                </Typography>
              </Stack>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      {/* Send RFQ To New Vendors start */}
      <Dialog
        id='sendRFQToNewVendor'
        open={anchorEl1}
        onClose={handleSendRfqToNewVendorClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.send_rfq_to_new_vendor}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleSendRfqToNewVendorClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers={scroll === 'paper'} >
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            // <div dangerouslySetInnerHTML={{ __html: sendRfqToNewVendor }}></div>
            <InnerHTML html={sendRfqToNewVendor} className="RFQ-PerlData SendRFQ-NV-Modal-Perl" />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={anchorEl2}
        onClose={handleR2AReportsClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.r2a_reports}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleR2AReportsClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: R2AReports }} className="RFQ-PerlData"></div>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={anchorElExtendValidityThread}
        onClose={handleExtendValidityThreadClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.extend_validity_thread}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleExtendValidityThreadClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <InnerHTML html={extendValidityThread} />
          )}
        </DialogContent>
      </Dialog>
      <Dialog
        open={anchorElTB}
        onClose={handleTbClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          Technical Bid Preview
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleTbClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            <InnerHTML html={Tbpreview} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={anchorEl3}
        onClose={handleInquiryLogsClose}
        fullScreen
        sx={{ m: 4 }}
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.inquiry_logs}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleInquiryLogsClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            // <div dangerouslySetInnerHTML={inquiryLogs}></div>
            <InnerHTML html={inquiryLogs} className="RFQ-PerlData" />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={multipleSolutionAnchor}
        onClose={handleMultipleSolutionClose}
        fullScreen
      >
        <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
          {menulabels.view_multiple_solution}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleMultipleSolutionClose}
          sx={{ position: "absolute", right: 8, top: 13 }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {loader === true ? (
            <CircularProgress
              sx={{
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          ) : (
            // <div dangerouslySetInnerHTML={multipleSolution}></div>
            <InnerHTML html={multipleSolution} />
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
        >
          {SnackMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={prioritySnackbar}
        onClose={handleClosePrioritySnackbar}
      >
        <Alert
          onClose={handleClosePrioritySnackbar}
          severity="success"
          variant="filled"
        >
          Priority Status Changed Successfully
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
