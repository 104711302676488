import { Box, Collapse, Divider, FormControl, MenuItem, Select, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ListIcon from '@mui/icons-material/ListOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAltOutlined';
import HighChartsColumn from '../RFQList/R2AReportChart/columnChart';
import R2aAuctionTableSection from './R2aAuctionTableSection';

function R2AReportGraph(props) {

    // Tabs
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const [viewChartTabs, setViewChartTabs] = React.useState(0);
    const ViewChartChange = (event, NewViewChartTabs) => {
        setViewChartTabs(NewViewChartTabs);
    };

    const [itemsList, setItemsList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [selectedItem, setSelectedItem] = React.useState({});
    const [selectedVendor, setSelectedVendor] = React.useState({});
    const [selectedSortOrder, setSelectedSortOrder] = React.useState("descending");

    React.useEffect(() => {
        if (props.auctionDetailforGraph && props.auctionDetailforGraph?.items && props.auctionDetailforGraph?.items.length > 0) {
            const itemLists = props.auctionDetailforGraph?.items.map((data) => ({
                id: data.id,          // assuming each item has an 'id' property
                name: data.name,       // assuming each item has a 'name' property
                code: data.code
            }));


            setItemsList(itemLists);

            if (itemLists.length > 0) {
                setSelectedItem({ name: itemLists[0].name, id: itemLists[0].id, code: itemLists[0].code });
            }

            // Function to get unique vendor IDs from props.auctionDetailforGraph.items
            const getAuctionsVendorsIds = () => [
                ...new Set(
                    props.auctionDetailforGraph?.items.flatMap(item => [
                        item.inquiry_lowest_vendor_id,
                        ...item.r2a_data.map(data => data.vendor_id)
                    ])
                )
            ];

            // Create a Map for vendor data using mainR2aResponse.rfq_vendors
            const vendorMap = new Map();

            props.mainR2aResponse.rfq_vendors.forEach(data => {
                vendorMap.set(data.id, {
                    id: data.id,
                    name: data.name,
                });
            });

            // Retrieve unique vendor IDs
            const uniqueVendorIds = getAuctionsVendorsIds();

            // Filter vendorArray based on uniqueVendorIds
            // const vendorArray = Array.from(vendorMap.values());
            const vendorArray = Array.from(vendorMap.values()).filter(vendor =>
                uniqueVendorIds.includes(vendor.id)
            );

            // Set the filtered vendorArray in vendorList
            setVendorList(vendorArray);

            // Set the first vendor's ID as the default selected value, if vendor list is not empty
            if (vendorArray.length > 0) {
                setSelectedVendor(vendorArray[0]);
            }
        }
    }, [props.auctionDetailforGraph]);

    const handleItemChange = (event) => {
        const selectedItemId = event.target.value;
        const selectedItemObj = itemsList.find((item) => item.id == selectedItemId);
        setSelectedItem(selectedItemObj);
    };

    const handleVendorChange = (event) => {
        const selectedVendorId = event.target.value;
        const selectedVendorObj = vendorList.find((vendor) => vendor.id == selectedVendorId);
        setSelectedVendor(selectedVendorObj);
    };

    const handleSortingChange = (event) => {
        const selectedSortOrder = event.target.value;
        setSelectedSortOrder(selectedSortOrder);
        props.onDataChange(selectedSortOrder);
    };

    return (
        <>
            <Collapse in={props.expandedR2AChart} timeout="auto" unmountOnExit>
                <Divider />
                <Box
                    className="ViewChartTabs"
                    sx={{
                        p: 2,
                        "&.ViewChartTabs": {
                            ".MuiTabs-flexContainer .MuiButtonBase-root": {
                                border: "1px solid var(--bluegrey-500)",
                                borderRadius: "5px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                                ":first-child": {
                                    borderTopRightRadius: "0",
                                    borderBottomRightRadius: "0",
                                    borderRight: "0"
                                },
                                ":last-child": {
                                    borderTopLeftRadius: "0",
                                    borderBottomLeftRadius: "0",
                                    borderLeft: "0"
                                },
                                "&.Mui-selected": {
                                    background: "var(--primary-50)",
                                    border: "1px solid var(--primary-500)",
                                },
                                "@media screen and (max-width:600px)": {
                                    width: "50%",
                                    justifyContent: "center"
                                },
                            },
                            ".MuiTabs-indicator": {
                                display: "none"
                            },
                            ".MuiButtonBase-root": {
                                minHeight: "30px"
                            },
                            ".ViewChartSelect": {
                                display: "flex",
                                justifyContent: "end",
                                gap: "16px",
                                marginTop: "-48px",
                                "@media screen and (max-width:900px)": {
                                    marginTop: "0",
                                },
                                "@media screen and (max-width:600px)": {
                                    flexDirection:"column",
                                    gap:"0"
                                }
                            },
                            ".MuiFormControl-root": {
                                width: 300,
                                "@media screen and (max-width:1200px)": {
                                    width: 250,
                                },
                                "@media screen and (max-width:900px)": {
                                    width: "50%",
                                },
                                "@media screen and (max-width:600px)": {
                                    width: "100%",
                                    margin: "8px auto 0"
                                },
                            },

                        }
                    }}
                >
                    <Tabs value={viewChartTabs} onChange={ViewChartChange} aria-label="basic tabs example">
                        <Tab icon={<ListIcon />} iconPosition="start" label="ITEM" />
                        <Tab icon={<PeopleAltIcon />} iconPosition="start" label="Vendor" />
                    </Tabs>

                    <TabPanel value={viewChartTabs} index={0} className="tabs-panel">
                        <Box className="ViewChartSelect">
                            <FormControl size="small">
                                <Select value={selectedSortOrder} onChange={handleSortingChange}>
                                    <MenuItem key="desc" value="descending">
                                        Highest to lowest
                                    </MenuItem>
                                    <MenuItem key="asc" value="ascending">
                                        Lowest to Highest
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small">
                                <Select value={selectedItem.id || ''} onChange={handleItemChange}>
                                    {itemsList.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name + ' [' + item.code + ']'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box p={2} pb={0} textAlign="center">
                            <Box className="ColumnChart-Box">
                                <HighChartsColumn chartName={selectedItem} auctionDetail={props.auctionDetailforGraph} index={0} rfqVendorsArr={props.mainR2aResponse.rfq_vendors} selectedSortOrder={selectedSortOrder} />
                            </Box>
                        </Box>
                    </TabPanel>


                    <TabPanel value={viewChartTabs} index={1} className="tabs-panel">
                        <Box className="ViewChartSelect">
                            <FormControl size="small">
                                <Select value={selectedSortOrder} onChange={handleSortingChange}>
                                    <MenuItem key="desc" value="descending">
                                        Highest to lowest
                                    </MenuItem>
                                    <MenuItem key="asc" value="ascending">
                                        Lowest to Highest
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl size="small">
                                <Select value={selectedVendor.id || ''} onChange={handleVendorChange} >
                                    {vendorList.map((vendor) => (
                                        <MenuItem key={vendor.id} value={vendor.id}>
                                            {vendor.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box p={2} textAlign="center">
                            <HighChartsColumn chartName={selectedVendor} auctionDetail={props.auctionDetailforGraph} index={1} rfqVendorsArr={props.mainR2aResponse.rfq_vendors} selectedSortOrder={selectedSortOrder} />
                        </Box>

                    </TabPanel>
                </Box>
            </Collapse>

        </>
    )
}

export default R2AReportGraph;
