import { Box, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter, daDK } from '@mui/x-data-grid-pro';
import React, { useCallback, useEffect, useState } from 'react';
import Grid from "@mui/material/Unstable_Grid2";

function R2aAuctionTableSection(props) {
    const [itemsData, setItemsData] = React.useState([]);
    React.useEffect(() => {
        if (props.auctionDetail && props.auctionDetail?.items && props.auctionDetail?.items.length > 0) {
            // Transform the data to fit the required format
            const transformedData = props.auctionDetail?.items.map((data, index) => {

                let lowestAuctionRate = Infinity; // Start with a high value
                let lowestVendorName = '';
                // Iterate through r2a_data to find the lowest auction rate
                data.r2a_data.forEach((r2a) => {
                    // console.log(r2a.auction_rate);
                    if(r2a.auction_rate != 0 && r2a.vendor_name != ""){
                        if (r2a.auction_rate < lowestAuctionRate) {
                            lowestAuctionRate = r2a.auction_rate;
                            lowestVendorName = r2a.vendor_name; // Store the corresponding vendor name
                        }
                    }
                });

                // Calculate total savings and handle cases where the value is NaN or 0.00
                const totalSavingsAuction = (data.inquiry_lowest_landed_cost && lowestAuctionRate)
                    ? ((data.inquiry_lowest_landed_cost - lowestAuctionRate) < 0
                        ? 0
                        : ((data.inquiry_lowest_landed_cost - lowestAuctionRate).toFixed(2) != 0
                            ? (data.inquiry_lowest_landed_cost - lowestAuctionRate).toFixed(2)
                            :0))// '0.00'))
                    : 0;

                // Calculate savingsIn
                const savingsInAuction = (totalSavingsAuction !== '-')
                    ? ((totalSavingsAuction * 100 / data.inquiry_lowest_landed_cost).toFixed(2) != 0
                        ? (totalSavingsAuction * 100 / data.inquiry_lowest_landed_cost).toFixed(2)
                        : 0)//0.00')
                    : 0//'0.00';

                // Find vendor name in rfq_vendors

                const matchedVendorObjects = props.rfq_vendors.find((item) => item.id == data.inquiry_lowest_vendor_id);

                return {
                    id: data.id,
                    itemName: data.name,
                    itemCode: data.code,
                    quantityUom: `${data.quantity}/${data.uom}`,
                    rfqLowCost: (data.inquiry_lowest_landed_cost != 0) ? data.inquiry_lowest_landed_cost.toFixed(2) : 0,
                    rfqVendorName: matchedVendorObjects?.name || "-", // Lowest vendor
                    auctionLowBid: lowestAuctionRate != Infinity ? lowestAuctionRate.toFixed(2) : 0, // Set to '-' if no valid auction rate found
                    auctionVendorName: lowestVendorName || '-', // Set to '-' if no vendor found
                    totalSavingAuction: totalSavingsAuction, // You can calculate this based on your needs
                    totalSavingAuctinPer: savingsInAuction, // You can calculate this based on your needs
                    r2a_data: data.r2a_data
                };
            });

            // Sorting Data with props
            const sortedData = transformedData.sort((a, b) => {
                // Parse totalSavingAuctinPer for both items
                const savingA = parseFloat(a.totalSavingAuctinPer) || 0;
                const savingB = parseFloat(b.totalSavingAuctinPer) || 0;
            
                // Handle cases where both have 0 totalSavingAuctinPer
                if (savingA == 0 && savingB == 0) {
                    // Sort by auctionLowBid in ascending or descending order
                    const auctionRateA = parseFloat(a.auctionLowBid) || Infinity;
                    const auctionRateB = parseFloat(b.auctionLowBid) || Infinity;
            
                    return props.sortingData == 'descending'
                        ? auctionRateB - auctionRateA  // Descending for auction rate
                        : auctionRateA - auctionRateB; // Ascending for auction rate
                }
            
                // If only one of them has 0 savings, prioritize the other
                if (savingA == 0) return 1; // Place `a` after `b`
                if (savingB == 0) return -1; // Place `b` after `a`
            
                // Sort by totalSavingAuctinPer
                return props.sortingData == 'descending'
                    ? savingB - savingA // Descending order
                    : savingA - savingB; // Ascending order
            });
            
            setItemsData(sortedData);
        }
    }, [props.auctionDetail, props.sortingData]);

    

    //for child table
    function CustomGridToolbarR2A() {
        return (
            <>
                <GridToolbarContainer className="datagrid-toolbar">
                    <Grid container spacing={0} sx={{
                        width: '100%',
                        '&.MuiGrid2-root': {
                            paddingTop: { xs: '0', sm: '0', md: '0', alignItems: 'center' },
                        }
                    }}>
                        <Grid xs={12} sm={12} md={3} xl={4}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                                <GridToolbarQuickFilter className="DataGrid-SearchBar" sx={{ pb: 0 }} />
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={12} md={9} xl={8} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                            <Box className="toolbar-right">
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>&nbsp;</Box>
                                <Box className='dg-filter' >
                                    <GridToolbarColumnsButton />
                                    <GridToolbarExport />
                                    <GridToolbarFilterButton />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>

                <GridToolbarContainer className="datagrid-toolbar" sx={{ display: { sm: 'flex', md: 'none' } }}>
                    <Grid container spacing={0} sx={{ width: '100%', alignItems: 'center' }}>
                        <Grid xs={2} md={6}>
                            <Box>&nbsp;</Box>
                        </Grid>
                        <Grid xs={10} md={6} sx={{ textAlign: { xs: 'right', sm: 'right' } }}>
                            <Box className='dg-filter' >
                                <GridToolbarColumnsButton />
                                <GridToolbarExport />
                                <GridToolbarFilterButton />
                            </Box>
                        </Grid>
                    </Grid>
                </GridToolbarContainer>
            </>
        );
    }
    /*##############Child Section###################*/
    // DataGrid Master Details child data

    // function DetailPanelContent({ row }) {
    //     const [sortChildData, setsortChildData] = React.useState([]);
    //     // Determine if the dynamic key "ms_name||slab_name" is present in the first entry of r2a_data


    //     const hasDynamicColumn = row.r2a_data.length > 0 &&
    //         (row.r2a_data[0].hasOwnProperty('ms_name') || row.r2a_data[0].hasOwnProperty('slab_range_name'));

    //     // Determine the header name for the dynamic column
    //     const dynamicColumnHeaderName = row.r2a_data[0].hasOwnProperty('ms_name') ? 'MS Name' :
    //         row.r2a_data[0].hasOwnProperty('slab_range_name') ? 'Slab Range Name' : '';

    //     React.useEffect(() => {
    //         // Map the r2a_data from the row to the rows format needed for DataGridPro
    //         const rows = row.r2a_data.map((r2a, index) => {

    //             // Calculate total savings and handle cases where the value is NaN or 0.00
    //             const totalSavings = (r2a.inquiry_rate && r2a.auction_rate)
    //                 ? ((r2a.inquiry_rate - r2a.auction_rate).toFixed(2) !== '0.00'
    //                     ? (r2a.inquiry_rate - r2a.auction_rate).toFixed(2)
    //                     : '-')
    //                 : '-';

    //             // Calculate savingsIn
    //             const savingsIn = (totalSavings !== '-')
    //                 ? ((totalSavings * 100 / r2a.inquiry_rate).toFixed(2) !== '0.00'
    //                     ? (totalSavings * 100 / r2a.inquiry_rate).toFixed(2)
    //                     : '-')
    //                 : '-';

    //             return {
    //                 id: index + 1,
    //                 vendorName: r2a.vendor_name,
    //                 dynamicColumn: r2a.ms_name || r2a.slab_range_name,  // For the dynamic column
    //                 RFQRate: r2a.inquiry_rate,
    //                 auctionRate: r2a.auction_rate,
    //                 totalSavings: totalSavings, // Use the calculated total savings
    //                 savingsIn: (savingsIn !== '-') ? `${savingsIn}%` : '-',
    //             };
    //         });

    //         // Sort the rows first by savingsIn in descending order, then by auctionRate if savingsIn is '-'


    //         const sortedRows = rows.sort((a, b) => {
    //             // Check if both savingsIn values are numbers
    //             // if (typeof a.totalSavings == 'number' && typeof b.totalSavings == 'number') {
    //                 if (props.sortingData == 'descending') {
    //                     return b.totalSavings - a.totalSavings; // Sort in descending order of savingsIn
    //                 } else {
    //                     return a.totalSavings - b.totalSavings; // Sort in ascending order of auctionRate
    //                 }
    //             // }
    //             // If savingsIn is '-', compare by auctionRate
    //             // if (a.savingsIn == '-' && b.savingsIn == '-') {
    //             //     return b.auctionRate - a.auctionRate; // Sort in ascending order of auctionRate
    //             // }
    //             // If one of the savingsIn is '-', place it after the other
    //             return a.totalSavings == '-' ? 1 : -1;
    //         });

    //         setsortChildData(sortedRows);

    //     }, [row.r2a_data,props.sortingData]);


    // const columns = [
    //     {
    //         field: 'vendorName',
    //         headerName: 'Vendor Name',
    //         width: 200,
    //     },

    //     // Conditionally add the dynamic column if it is present
    //     ...(hasDynamicColumn ? [{
    //         field: 'dynamicColumn',
    //         headerName: dynamicColumnHeaderName,
    //         width: 200,
    //     }] : []),

    //     {
    //         field: 'RFQRate',
    //         headerName: 'RFQ Rate',
    //         width: 150,
    //         editable: true,
    //     },
    //     {
    //         field: 'auctionRate',
    //         headerName: 'Auction Rate',
    //         width: 150,
    //     },
    //     {
    //         field: 'totalSavings',
    //         headerName: 'Total Savings',
    //         width: 150,
    //     },
    //     {
    //         field: 'savingsIn',
    //         headerName: 'Savings In (%)',
    //         width: 150,
    //     },

    // ];

    //     //  const rows = [
    //     // //     { id: 1, vendorName: 'Harsh', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     { id: 2, vendorName: 'Jay', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     { id: 3, vendorName: 'Farhan', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 4, vendorName: 'Yasir', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 5, vendorName: 'Harsh', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 6, vendorName: 'Sanajana', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 7, vendorName: 'Farhan', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 8, vendorName: 'Yasir', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 9, vendorName: 'Harsh', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 10, vendorName: 'Sanajana', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 11, vendorName: 'Farhan', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     // //     // { id: 12, vendorName: 'Yasir', RFQRate: '1200.00', auctionRate: '1200.00', totalSavings: '-', savingsIn: '-', },
    //     //  ];

    //     return (
    //         <Stack
    //             sx={{ pt: 2, boxSizing: 'border-box', borderBottom: '1px solid var(--bluegrey-500)' }}
    //             direction="column"
    //         >
    //             <Paper sx={{ flex: 1, mx: 'auto', width: 'calc(100% - 32px)', borderRadius: '0', boxShadow: 'none', mb: 2, }}>
    //                 <DataGridPro
    //                     rows={sortChildData}
    //                     columns={columns}
    //                     columnHeaderHeight={40}
    //                     disableRowSelectionOnClick
    //                     sx={{
    //                         height: '250px',
    //                         '&.MuiDataGrid-root': {
    //                             borderRadius: "0",
    //                             borderWidth: "1px",
    //                             borderColor: "var(--bluegrey-500)"
    //                         }
    //                     }}
    //                     hideFooter
    //                 />
    //             </Paper>
    //         </Stack>

    //     );
    // }

    function DetailPanelContent({ row }) {

        const [sortChildData, setSortChildData] = useState([]);

        // Determine if the dynamic key "ms_name" or "slab_range_name" is present in the first entry of r2a_data
        const hasDynamicColumn = row.r2a_data.length > 0 &&
            (row.r2a_data[0].hasOwnProperty('ms_name') || row.r2a_data[0].hasOwnProperty('slab_range_name'));

        // Set the header name for the dynamic column
        const dynamicColumnHeaderName = row.r2a_data[0].hasOwnProperty('ms_name') ? 'MS Name' :
            row.r2a_data[0].hasOwnProperty('slab_range_name') ? 'Slab Range Name' : '';

           
        useEffect(() => {
            const rows = row.r2a_data.map((r2a, index) => {
                const totalSavings = r2a.inquiry_rate && r2a.auction_rate
                    ? (r2a.inquiry_rate - r2a.auction_rate).toFixed(2) !== '0.00'
                        ? (r2a.inquiry_rate - r2a.auction_rate).toFixed(2)
                        : 0//'-'
                    : 0;//'-';
                   
                const savingsIn = (totalSavings !== '-') ? ((totalSavings * 100 / r2a.inquiry_rate).toFixed(3) !== '0.00'
                        ? `${(totalSavings * 100 / r2a.inquiry_rate).toFixed(2)}`
                        : 0)//'-')
                    : 0;//'-';

                return {
                    id: index + 1,
                    //vendorName: r2a.vendor_name || '-',// here add condition r2a.vendor_name=="" then use this else  const matchedVendorObjects = props.rfq_vendors.find((item) => item.id == data.inquiry_lowest_vendor_id); and print matchedVendorObjects.name
                    vendorName: r2a.vendor_name && r2a.vendor_name.trim() != "" 
                                ? r2a.vendor_name 
                                : (() => {
                                    const matchedVendorObject = props.rfq_vendors.find(
                                        (item) => item.id == r2a.vendor_id
                                    );
                                    return matchedVendorObject ? matchedVendorObject.name : "-";
                                })(),
                    dynamicColumn: r2a.ms_name || r2a.slab_range_name,
                    RFQRate: r2a.inquiry_rate.toFixed(2),
                    auctionRate: r2a.auction_rate.toFixed(2)|| 0,
                    totalSavings,
                    savingsIn,
                };
            });   

            //Sorting data
            const sortedRows = rows.sort((a, b)  => {
                // Parse totalSavingAuctinPer for both items
                const savingA = parseFloat(a.savingsIn) || 0;
                const savingB = parseFloat(b.savingsIn) || 0;
            
                // Handle cases where both have 0 totalSavingAuctinPer
                if (savingA == 0 && savingB == 0) {
                    // Sort by auctionLowBid in ascending or descending order
                    const auctionRateA = parseFloat(a.auctionRate) || Infinity;
                    const auctionRateB = parseFloat(b.auctionRate) || Infinity;
            
                    return props.sortingData == 'descending'
                        ? auctionRateB - auctionRateA  // Descending for auction rate
                        : auctionRateA - auctionRateB; // Ascending for auction rate
                }
            
                // If only one of them has 0 savings, prioritize the other
                if (savingA == 0) return 1; // Place `a` after `b`
                if (savingB == 0) return -1; // Place `b` after `a`
            
                // Sort by totalSavingAuctinPer
                return props.sortingData == 'descending'
                    ? savingB - savingA // Descending order
                    : savingA - savingB; // Ascending order
            });
            

            setSortChildData(sortedRows);


        }, [row.r2a_data, props.sortingData]);

        const columns = [
            { field: 'vendorName', headerName: 'Vendor Name', width: 200 },
            ...(hasDynamicColumn ? [{ field: 'dynamicColumn', headerName: dynamicColumnHeaderName, width: 200 }] : []),
            {
                field: 'RFQRate', headerName: 'RFQ Rate', width: 150, editable: true, headerAlign: "right", align: "right",
                renderCell: (params) => {
                    return (
                        <Typography variant="body2" fontWeight={500}>
                            {
                                params.value == 0 || params.value == '-'
                                ? '-'
                                : parseFloat(params.value).toFixed(2)
                            //     parseFloat(params.value).toLocaleString("en-IN", {
                            //     minimumFractionDigits: 3,
                            //     maximumFractionDigits: 3,
                            // })
                        }</Typography>
                    );
                },
            },
            {
                field: 'auctionRate', headerName: 'Auction Rate', width: 150, headerAlign: "right", align: "right",
                renderCell: (params) => {
                    return (
                        <Typography variant="body2" fontWeight={500}>
                        {
                         params.value == 0 
                         ? 'N/A'
                         : parseFloat(params.value).toFixed(2)
                            //parseFloat(params.value).toLocaleString("en-IN", {
                            //     minimumFractionDigits: 3,
                            //     maximumFractionDigits: 3,
                            // })
                        }</Typography>
                    );
                },
            },
            {
                field: 'totalSavings', headerName: 'Total Savings', width: 150, headerAlign: "right", align: "right",
                renderCell: (params) => {
                    return (
                        <Typography variant="body2" fontWeight={500} color={"success.800"}>
                        {
                              params.value == 0 || params.value == '-'
                              ? '-'
                              : parseFloat(params.value).toFixed(2)
                            //    parseFloat(params.value).toLocaleString("en-IN", {
                            //         minimumFractionDigits: 3,
                            //         maximumFractionDigits: 3,
                            //     })
                        }</Typography>
                    );
                },
            },
            {
                field: 'savingsIn', headerName: 'Savings In (%)', width: 150, headerAlign: "right", align: "right",
                renderCell: (params) => {
                    return (
                        <Typography variant="body2" fontWeight={500} color={"success.800"}>
                        {   
                             params.value == 0 || params.value == '-'
                             ? '-'
                             :`${  parseFloat(params.value).toFixed(2)
                                //     parseFloat(params.value).toLocaleString("en-IN", {
                                //        minimumFractionDigits: 3,
                                //        maximumFractionDigits: 3,
                                //    })
                                }%`//if saving% is 0.000 then show -
                        }</Typography>
                    );
                },
            },
        ];

        return (
            <Stack
                sx={{ pt: 2, boxSizing: 'border-box', borderBottom: '1px solid var(--bluegrey-500)' }}
                direction="column"
            >
                <Paper sx={{ flex: 1, mx: 'auto', width: 'calc(100% - 32px)', borderRadius: '0', boxShadow: 'none', mb: 2 }}>
                    <DataGridPro
                        rows={sortChildData}
                        columns={columns}
                        columnHeaderHeight={40}
                        disableRowSelectionOnClick
                        density="compact"
                        sx={{
                            maxHeight: '200px',
                            '&.MuiDataGrid-root': {
                                borderRadius: "0",
                                borderWidth: "1px",
                                borderColor: "var(--bluegrey-500)"
                            }
                        }}
                        hideFooter
                    />
                </Paper>
            </Stack>
        );
    }

    const getDetailPanelHeight = React.useCallback(() => 'auto', []);

    //Master Details 
    const getDetailPanelContent = useCallback(
        ({ row }) => <DetailPanelContent row={row} sortingData={props.sortingData} />,
        [props.sortingData],
    );

    const columnsR2A = [
        {
            field: 'itemName',
            headerName: 'Item name',
            width: 320,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value} placement="bottom-start">
                        <Typography variant="body2" noWrap>{params.value}</Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: 'itemCode',
            headerName: 'Item code',
            width: 150
        },
        {
            field: 'quantityUom',
            headerName: 'Quantity/UOM',
            width: 160,
            headerAlign: "right",
            align: "right"
        },
        {
            field: 'rfqLowCost',
            headerName: 'RFQ Lowest landed cost',
            width: 200,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500}>
                        {
                            params.value.length > 0 || params.value != 0
                            ? parseFloat(params.value).toFixed(2)
                            // parseFloat(params.value).toLocaleString("en-IN", {
                            // minimumFractionDigits: 2,
                            // maximumFractionDigits: 2,
                        // })
                        : '-'
                    }</Typography>
                );
            },
        },
        {
            field: 'rfqVendorName',
            headerName: 'RFQ Vendor name',
            width: 150
        },
        {
            field: 'auctionLowBid',
            headerName: 'Auction lowest bid',
            width: 150,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500}>
                        {
                            params.value == 0 
                                ? '-' 
                                : parseFloat(params.value).toFixed(2)
                        }
                    </Typography>
                );
            },
        },
        {
            field: 'auctionVendorName',
            headerName: 'Auction lowest vendor name',
            width: 200
        },
        {
            field: 'totalSavingAuction',
            headerName: 'Total savings in auction',
            width: 200,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500} color={"success.800"}>
                    {
                        params.value != 0
                            ?  parseFloat(params.value).toFixed(2)
                            // parseFloat(params.value).toLocaleString("en-IN", {
                            //     minimumFractionDigits: 3,
                            //     maximumFractionDigits: 3,
                            // })
                            : '-'
                    }</Typography>
                );
            },
        },
        {
            field: 'totalSavingAuctinPer',
            headerName: 'Total savings in auction (%)',
            width: 200,
            headerAlign: "right",
            align: "right",
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight={500} color={"success.800"}>
                        {
                            params.value != 0.00 && params.value != 0 
                             ? 
                             `${ parseFloat(params.value).toFixed(2)
                            //     parseFloat(params.value).toLocaleString("en-IN", {
                            //        minimumFractionDigits: 3,
                            //        maximumFractionDigits: 3,
                            //    })
                            }%`
                             : '-' //if saving% is 0.000 then show -
                         }
                    </Typography>
                );
            },
        },
    ];

    return (
        <>
            <Box sx={{ height: "400", width: "100%" }}>
                <DataGridPro
                    rows={itemsData}
                    columns={columnsR2A}
                    headerHeight={40}
                    columnHeaderHeight={40}
                    checkboxSelection
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    pagination
                    pageSizeOptions={[25, 50, 100]}
                    slots={{
                        toolbar: CustomGridToolbarR2A,
                    }}
                    density="compact"
                    className="fullScreenGrid"
                    getDetailPanelHeight={getDetailPanelHeight}
                    getDetailPanelContent={(params) => {
                        // Replace with your condition
                        if (params.row.r2a_data.length == 0) {
                            return null; // Hide content if condition is met
                        }
                        return getDetailPanelContent(params);
                    }}
                    // getDetailPanelContent={getDetailPanelContent}
                    sx={{
                        '&.MuiDataGrid-root': {
                            borderRadius: "0",
                            borderWidth: "1px 0 0 0"
                        },
                        '& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell': {
                            borderBottomColor: '#a6c1d3'
                        }
                    }}
                    hideFooter
                />
            </Box>
        </>
    )
}

export default R2aAuctionTableSection;
