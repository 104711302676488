import React from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Box, Button, Collapse, Divider, List, ListItem, Tooltip, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';


function R2aAuctionDetails(props) {
    const rfqVendorCount = props.mainR2aResponse && props.mainR2aResponse.rfq_vendors
        ? props.mainR2aResponse.rfq_vendors.length
        : '-';
   
    const rfqItemsCount = props.auctionDetail?.items && props.auctionDetail?.items
        ? props.auctionDetail?.items.length
        : '-';

    return (
        <>
            <Collapse in={props.isR2ADetails ? true : props.expandedR2ADetails} timeout="auto" unmountOnExit>
                <List sx={{ "&.MuiList-root": { display: "flex", flexWrap: "wrap", ".MuiListItem-root": { display: "block", flex: "0 0 250px", overflow:"hidden" } } }}>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>Auction No</Typography>
                        <Tooltip title={props.auctionDetail?.number} placement="bottom-start">
                            <Typography variant="body2" color={"grey.900"}>{props.auctionDetail?.number}</Typography>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>Auction Name</Typography>
                        <Tooltip title={props.auctionDetail?.name} placement="bottom-start">
                            <Typography variant="body2" color={"grey.900"} noWrap>{props.auctionDetail?.name}</Typography>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>Category Name</Typography>
                        <Tooltip title={props.auctionDetail?.category_name} placement="bottom-start">
                            <Typography variant="body2" color={"grey.900"}>{props.auctionDetail?.category_name}</Typography>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>Auction Start Date</Typography>
                        <Typography variant="body2" color={"grey.900"}>
                            {moment(props.auctionDetail?.start_date, "YYYY-MM-DD HH:mm:ss IST").format("DD/MM/YYYY HH:mm:ss [IST]")}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>Auction End Date</Typography>
                        <Typography variant="body2" color={"grey.900"}>
                            {moment(props.auctionDetail?.end_date, "YYYY-MM-DD HH:mm:ss IST").format("DD/MM/YYYY HH:mm:ss [IST]")}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>No of Vendors in Auction</Typography>
                        <Typography variant="body2" color={"grey.900"}>{props.auctionDetail?.no_of_vendors}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>No of Item in Auction</Typography>
                        <Typography variant="body2" color={"grey.900"}>{props.auctionDetail?.no_of_item}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>RFQ No</Typography>
                        <Tooltip title={props.mainR2aResponse?.inquiry_no} placement="bottom-start">
                            <Typography variant="body2" color={"grey.900"}>{props.mainR2aResponse?.inquiry_no}</Typography>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>No of Vendors in RFQ</Typography>
                        <Typography variant="body2" color={"grey.900"}>{rfqVendorCount}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>No of Item in RFQ</Typography>
                        <Typography variant="body2" color={"grey.900"}>{rfqItemsCount}</Typography>
                    </ListItem>
                    <ListItem>
                        <Typography variant="caption" component="lable" display="block" color={"secondary.600"}>RFQ Date</Typography>
                        <Typography variant="body2" color={"grey.900"}>
                            {moment(props.mainR2aResponse?.date, "YYYY-MM-DD HH:mm:ss IST").format("DD/MM/YYYY HH:mm:ss [IST]")}
                        </Typography>
                    </ListItem>
                </List>
                <Divider />
            </Collapse>
        </>
    )
}

export default R2aAuctionDetails;
